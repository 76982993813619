<template>
  <div class="setting">
    <Button class="setting-btn back" type="default" size="large" @click="onBackClick">
      <Icon type="md-arrow-back" />&nbsp;&nbsp;返回
    </Button>
    <div class="setting-content">
      <div style="height: 80px;"></div>
      <h5 class="title">账号设置</h5>
      <p class="base-info">基本信息</p>
      <div class="member-tip">
        <XIcon iconClass="user_member" />
        如需升级或取消会员等，请前往APP进行操作。为此带来的不便，我们感到非常抱歉！
      </div>
      <div class="account setting-cell">
        <span></span>
        <div style="position: relative; width: 40px; height: 40px; margin-right: 16px;">
          <Image 
            v-if="userInfo.avatarUrl"
            class="hand"
            :src="userInfo.avatarUrl" 
            width="40px" 
            style="border-radius: 20px; overflow: hidden;"
            @click="onAvatarClick" />
          <XIcon class="attach" v-if="userInfo.memberType!='NORMAL'" iconClass="user_member" />
        </div>
        <div style="flex: 1;" class="user-title">
          <p class="nick-name" >{{ userInfo.nickName }}</p>
          <p class="member-title">{{ memberTitle }}</p>
        </div>
        <Button class="setting-btn" type="default" @click="onNickClick">修改昵称</Button>
      </div>
      <div class="setting-cell">
        <div>
          <p>邮箱</p>
          <p v-if="userInfo.email"><Icon type="ios-checkmark-circle" style="color: #43CF7C;" />{{ userInfo.email }}</p>
          <p v-else><Icon type="ios-information-circle" style="color: #42B4FF;" />未绑定</p>
        </div>
        <Button v-if="userInfo.email" class="setting-btn high" @click="onEmailClick">解除绑定</Button>
        <Button v-else class="setting-btn" @click="onEmailClick">立即绑定</Button>
      </div>
      <div v-if="userInfo.email" class="setting-cell">
        <div>
          <p>密码</p>
          <p><Icon type="ios-checkmark-circle" style="color: #43CF7C;" />已设置</p>
        </div>
        <Button class="setting-btn" @click="onModifyClick">修改密码</Button>
      </div>
      <p class="base-info">第三方账号绑定</p>
      <div class="setting-cell">
        <div>
          <p>微信</p>
          <p v-if="wechatInfo"><Icon type="ios-checkmark-circle" style="color: #43CF7C;" />{{ wechatInfo.openNickName }}</p>
          <p v-else><Icon type="ios-information-circle" style="color: #42B4FF;" />未绑定</p>
        </div>
        <Button v-if="wechatInfo" class="setting-btn high" @click="onWechatClick">解除绑定</Button>
        <Button v-else class="setting-btn" @click="onWechatClick">立即绑定</Button>
      </div>
      <div class="setting-cell">
        <div>
          <p>QQ</p>
          <p v-if="qqInfo"><Icon type="ios-checkmark-circle" style="color: #43CF7C;" />{{ qqInfo.openNickName }}</p>
          <p v-else><Icon type="ios-information-circle" style="color: #42B4FF;" />未绑定</p>
        </div>
        <Button v-if="qqInfo" class="setting-btn high" @click="onQQClick">解除绑定</Button>
        <Button v-else class="setting-btn" @click="onQQClick">立即绑定</Button>
      </div>
      <div class="setting-cell">
        <div>
          <p>微博</p>
          <p v-if="weiboInfo"><Icon type="ios-checkmark-circle" style="color: #43CF7C;" />{{ weiboInfo.openNickName }}</p>
          <p v-else><Icon type="ios-information-circle" style="color: #42B4FF;" />未绑定</p>
        </div>
        <Button v-if="weiboInfo" class="setting-btn high" @click="onWeiboClick">解除绑定</Button>
        <Button v-else class="setting-btn" @click="onWeiboClick">立即绑定</Button>
      </div>
      <p class="base-info">账号管理</p>
      <div class="setting-cell">
        <div>
          <p>注销账号</p>
          <p><Icon type="md-warning" style="color: #FF8D1A;" />注销账号，数据将清空</p>
        </div>
        <Button class="setting-btn high" @click="onDestroyClick">注销账号</Button>
      </div>
    </div>
  </div>
  <Modal
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true" 
    v-model="showNickModal" 
    width="420px" 
    title="修改昵称"
    @on-ok="onNickModalOk">
    <Input 
      style="margin: 24px 0px 30px 0px;" 
      v-model="nickName"
      placeholder="请输入昵称" />
  </Modal>
  <Modal     
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"  
    v-model="showAvatarModal" 
    width="420px" 
    @on-ok="onAvatarModalOk"
    title="修改头像"
    >
    <div style="display: flex;">
      <div style="width: 230px; height: 230px; margin: 24px 30px 0px 0px;">
        <vueCropper
          style="margin-bottom: 20px;"
          ref="cropper"
          :img="options.img"
          :autoCrop="options.autoCrop"
          :fixed="options.fixed"
          :centerBox="options.centerBox"
          @realTime="realTime"
        ></vueCropper>
        <Upload
          ref="upload"
          action=""
          :show-upload-list="false"
          accept="image/png, image/jpeg, image/jpg"
          :format="['jpg','jpeg','png']"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          :before-upload="handleBeforeUpload" >
          <span class="primary-color hand">{{ options.img.length > 0 ? '重新上传' : '选择图片' }}</span>
        </Upload>
      </div>
      <div style="width: 90px; margin-top: 24px;">
        <div v-if="previewInfo.url" :style="previewStyle"> 
          <div :style="previewInfo.div">
            <Image :src="previewInfo.url" :style="previewInfo.img" />
          </div>
        </div>
        <Image v-else-if="userInfo.avatarUrl" :src="userInfo.avatarUrl" with="90" style="border-radius: 45px; overflow: hidden;" />
        <p style="margin-top: 20px; font-size: 14px; text-align: center;" class="default-color">头像预览</p>
      </div>
    </div>
  </Modal>
  <Modal     
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"  
    v-model="showEmailModal" 
    width="420px" 
    @on-ok="onEmailModalOk"
    title="绑定邮箱"
    >
    <Form style="margin-top: 50px;" ref="form" :model="formdata" :rules="rules" label-position="left" :label-width="80">
      <FormItem prop="email" label="邮箱">
        <Input :border="false" size="large" type="email" v-model="formdata.email" placeholder="请输入邮箱"></Input>
      </FormItem>
      <FormItem prop="captcha" label="验证码">
        <Input style="flex: 1;" :border="false" size="large" type="text" v-model="formdata.captcha" placeholder="请输入验证码">
          <template #suffix>
            <Button type="text" class="captcha-button" @click="captchaClick(0)">{{ seconds == 60 ? '获取验证码' : seconds }}</Button>
          </template>
        </Input>
      </FormItem>
      <FormItem prop="password" label="密码">
        <Input :border="false" size="large" :type="showPassword1 ? 'text' : 'password'" v-model="formdata.password" placeholder="请输入密码,6-20个字符">
          <template #suffix>
            <div class="hand" @click="(showPassword1=!showPassword1)">
              <Icon v-if="showPassword1" style="line-height: 48px;" type="md-eye" class="default-color"/>
              <XIcon v-else iconClass="eye" size="16" style="margin: 16px 0px;" />
            </div>
          </template>
        </Input>
      </FormItem>
      <FormItem prop="password" label="确认密码">
        <Input :border="false" size="large" :type="showPassword2 ? 'text' : 'password'" v-model="formdata.passwordConfirm" placeholder="请确认密码">
          <template #suffix>
            <div class="hand" @click="(showPassword2=!showPassword2)">
              <Icon v-if="showPassword2" style="line-height: 48px;" type="md-eye" class="default-color"/>
              <XIcon v-else iconClass="eye" size="16" style="margin: 16px 0px;" />
            </div>
          </template>
        </Input>
      </FormItem>
    </Form>
  </Modal>
  <Modal 
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true" 
    v-model="showEmailUnbindModal" 
    width="420px" 
    @on-ok="onEmailUnbindModalOk"
    title="解除绑定"
    >
    <Form style="margin-top: 50px;" ref="unbindForm" :model="formdata" :rules="unbindRules" label-position="left" :label-width="80">
      <FormItem prop="email" label="邮箱">
        <Input readonly :border="false" size="large" type="email" v-model="formdata.email" placeholder="请输入邮箱"></Input>
      </FormItem>
      <FormItem prop="captcha" label="验证码">
        <Input style="flex: 1;" :border="false" size="large" type="text" v-model="formdata.captcha" placeholder="请输入验证码">
          <template #suffix>
            <Button type="text" class="captcha-button" @click="captchaClick(2)">{{ seconds == 60 ? '获取验证码' : seconds }}</Button>
          </template>
        </Input>
      </FormItem>
    </Form>
  </Modal>
  <Modal     
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"  
    v-model="showModifyModal" 
    width="420px" 
    @on-ok="onModifyModalOk"
    title="修改密码">
    <Form style="margin-top: 50px;" ref="form" :model="formdata" :rules="modifyRules" label-position="left" :label-width="80">
      <FormItem prop="password" label="当前密码">
        <Input :border="false" size="large" :type="showPassword1 ? 'text' : 'password'" v-model="formdata.password" placeholder="请输入当前密码">
          <template #suffix>
            <div class="hand" @click="(showPassword1=!showPassword1)">
              <Icon v-if="showPassword1" style="line-height: 48px;" type="md-eye" class="default-color" />
              <XIcon v-else iconClass="eye" size="16" style="margin: 16px 0px;" />
            </div>
          </template>
        </Input>
      </FormItem>
      <FormItem prop="password" label="新密码">
        <Input :border="false" size="large" :type="showPassword2 ? 'text' : 'password'" v-model="formdata.passwordConfirm" placeholder="请输入新密码">
          <template #suffix>
            <div class="hand" @click="(showPassword2=!showPassword2)">
              <Icon v-if="showPassword2" style="line-height: 48px;" type="md-eye" class="default-color"/>
              <XIcon v-else iconClass="eye" size="16" style="margin: 16px 0px;" />
            </div>
          </template>
        </Input>
      </FormItem>
    </Form>
  </Modal>
  <Modal     
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"  
    v-model="showThirdUnbindModal" 
    width="420px" 
    @on-ok="onThirdUnbindModalOk"
    title="解除绑定">
    <p style="margin: 20px 0px 50px 0px; font-size: 14px;">解除绑定后，将无法使用{{ typeKeys[unbindInfo.type] }}账号：“{{ unbindInfo.openNickName }}”登录并关联当前账号。<br/>确定要解除绑定吗？</p>
  </Modal>
  <Modal     
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"  
    v-model="showErrorUnbindModal" 
    width="420px"
    title="解除绑定">
    <p style="margin: 20px 0px 50px 0px; font-size: 14px;" class="default-color">当前{{ typeKeys[unbindInfo.type] }}账号为初始注册账户，无法进行解绑，如要彻底删除，请点击底部注销账号。</p>
  </Modal>
  <Modal     
    class="drag-modal"
    draggable
    sticky
    scrollable
    :mask="true"  
    v-model="showDestroyModal" 
    width="420px" 
    @on-ok="onDestroyModalOk"
    title="注销账号">
    <p style="margin: 20px 0px; font-size: 14px;" class="default-color">
      该操作将会永久删除当前账号，以及账号相关的所有数据。注销后账号无法再次找回。<br/>
      如您确认要注销，请输入以下确认码：
    </p>
    <p style="margin-bottom: 20px; font-size: 16px; text-align: center;" class="default-color">{{ confirmCode }}</p>
    <Input 
      :maxlength="6"
      style="margin-bottom: 50px;" 
      v-model="confirmInput"
      placeholder="请输入确认码" />
  </Modal>
</template>

<script>
import { 
  userInfo,
  modifyInfo,
  ossUpload,
  captcha,
  bindEmail,
  unbindEmail,
  unbindThird,
  userDestroy,
  modifyPassword
} from '@/common/api';
import 'vue-cropper/dist/index.css'
import { VueCropper }  from "vue-cropper";

export default {
  components: {
    VueCropper
  },
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value !== this.$refs.form.formValidate.password) {
        callback(new Error('两次输入的密码不匹配！'));
      } else {
        callback();
      }
    };
    return {
      typeKeys: {
        'QQ': 'QQ',
        'WECHAT': '微信',
        'WEIBO': '微博',
        'EMAIL': '邮箱'
      },
      confirmCode: '123456',
      confirmInput: '',

      showNickModal: false,
      nickName: '',

      options: {
        img: '', // 原图文件
        autoCrop: true, // 默认生成截图框
        fixedBox: false, // 固定截图框大小
        canMoveBox: true, // 截图框可以拖动
        autoCropWidth: 180, // 截图框宽度
        autoCropHeight: 180, // 截图框高度
        fixed: true, // 截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        centerBox: true, // 截图框被限制在图片里面
        canMove: false, // 上传图片不允许拖动
        canScale: false // 上传图片不允许滚轮缩放
      },
      previewStyle: '',
      previewInfo: {},
      showAvatarModal: false, 

      seconds: 60,
      showEmailModal: false,
      formdata: {},
      showPassword1: false,
      showPassword2: false,
      showModifyModal: false,
      rules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { type: 'string', min: 6, max: 20, message: '请输入六到二十位的密码', trigger: 'blur' }
        ],
        passwordConfirm: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { validator: validatePassCheck, trigger: 'blur' }
        ],
      },
      unbindRules: {
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
      },
      modifyRules: {
        password: [
          { required: true, message: '请输入当前密码', trigger: 'blur' },
        ],
        passwordConfirm: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { type: 'string', min: 6, max: 20, message: '请输入六到二十位的新密码', trigger: 'blur' }
        ],
      },

      unbindInfo: {},
      showThirdUnbindModal: false,
      showEmailUnbindModal: false,
      showErrorUnbindModal: false,

      showDestroyModal: false
    }
  },
  mounted() {
    if (!this.userInfo.userId) {
      this.loadInfo();
    } else {
      this.nickName = this.userInfo.nickName;
    }
  },
  computed: {
    qqInfo() {
      if (!this.userInfo.openInfos || this.userInfo.openInfos.length == 0) return null;
      return this.userInfo.openInfos.find(item => item.type == 'QQ');
    },
    wechatInfo() {
      if (!this.userInfo.openInfos || this.userInfo.openInfos.length == 0) return null;
      return this.userInfo.openInfos.find(item => item.type == 'WECHAT');
    },
    weiboInfo() {
      if (!this.userInfo.openInfos || this.userInfo.openInfos.length == 0) return null;
      return this.userInfo.openInfos.find(item => item.type == 'WEIBO');
    },
    userInfo() {
      return this.$store.state.user;
    },
    memberTitle() {
      let memberType = this.userInfo.memberType;
      switch (memberType) {
        case 'NORMAL':
          return '普通用户';
        case 'MONTH':
          return '月度会员';
        case 'YEAR':
          return '年度会员';
        case 'PERMANENT':
          return '永久会员';
        default:
          return '普通用户';
      }
    },
  },
  methods: {
    onBackClick() {
      this.$router.go(-1);
    },
    onComplete() {
      this.$router.push('/manager/work');
    },
    onMenuSelect(name) {
      this.activeName = name;
      this.$router.push({name});
    },
    onNickClick() {
      this.showNickModal = true;
    },
    onNickModalOk() {
      if (this.nickName.length == 0) return;
      modifyInfo(this.nickName).then(res => {
        if (res) {
          this.userInfo.nickName = this.nickName;
        }
      });
    },
    onAvatarClick() {
      this.showAvatarModal = true;
    },
    handleFormatError() {
      this.$Message.error('请选择图片');
    },
    handleMaxSize() {
      this.$Message.error('图片最大为2M');
    },
    handleBeforeUpload(file) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.options.img = e.target.result // base64
      }
    },
    realTime(data) {
      this.previewStyle = {
        width: data.w + "px",
        height: data.h + "px",
        overflow: "hidden",
        margin: "0",
        zoom: 100 / data.w,
        borderRadius: data.w / 2 + "px"
      }
      this.previewInfo = data;
    },
    onAvatarModalOk() {
      if (this.previewInfo.img) {
        this.$refs.cropper.getCropData(data => {
          let fileName = this.$store.state.user.userId + '/' + Date.now() + 'avatar.jpg';
          let file = this.blobToFile(this.dataURLtoBlob(data), fileName);
          ossUpload(fileName, file).then(res => {
            if (res) {
              this.options.img = '';
              this.previewStyle = {};
              this.previewInfo = {};

              this.userInfo.avatar = fileName;
              this.userInfo.avatarUrl = res.url;
              modifyInfo(null, fileName);
            }
          });
        });
      }
    },
    // base64转Blob
    dataURLtoBlob(dataurl) { 
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    // Blob转file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
    onModifyClick() {
      this.showPassword1 = false;
      this.showPassword2 = false;
      this.formdata = {};
      this.showModifyModal = true;
    },
    onEmailClick() {
      if (this.userInfo.email) {
        if (this.userInfo.registerType == 'EMAIL') {
          this.unbindInfo = {
            type: 'EMAIL'
          };
          this.showErrorUnbindModal = true;
        } else {
          this.showEmailUnbindModal = true;
        }
      } else {
        this.showPassword1 = false;
        this.showPassword2 = false;
        this.formdata = {};
        this.showEmailModal = true;
      }
    },
    onWechatClick() {
      if (this.wechatInfo) {
        if (this.wechatInfo.type == this.userInfo.registerType) {
          this.showErrorUnbindModal = true;
        } else {
          this.unbindInfo = this.wechatInfo;
          this.showThirdUnbindModal = true;
        }
      } else {
        this.$Message.info('抱歉，暂时需要前往APP绑定第三方账号~');
      }
    },
    onQQClick() {
      if (this.qqInfo) {
        if (this.qqInfo.type == this.userInfo.registerType) {
          this.showErrorUnbindModal = true;
        } else {
          this.unbindInfo = this.qqInfo;
          this.showThirdUnbindModal = true;
        }
      } else {
        this.$Message.info('抱歉，暂时需要前往APP绑定第三方账号~');
      }
    },
    onWeiboClick() {
      if (this.weiboInfo) {
        if (this.weiboInfo.type == this.userInfo.registerType) {
          this.showErrorUnbindModal = true;
        } else {
          this.unbindInfo = this.weiboInfo;
          this.showThirdUnbindModal = true;
        }
      } else {
        this.$Message.info('抱歉，暂时需要前往APP绑定第三方账号~');
      }
    },
    onEmailModalOk() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          bindEmail(this.formdata.email, this.formdata.password, this.formdata.captcha).then(res => {
            if (res) {
              this.userInfo.email = this.formdata.email;
            }
          });
        }
      })
    },
    captchaClick(type = 0) {
      if (this.seconds < 60) return;
      if (!this.formdata.email) {
        return;
      }
      captcha(this.formdata.email, type);
      var interval = setInterval(() => {
        if (this.seconds == 1) {
          this.seconds = 60;
          clearInterval(interval);
        } else {
          this.seconds--;
        }
      }, 1000);
    // this.$refs.form.validateField('email')
    //   this.$refs['form'].validateField('email', (valid) => {
    //     console.log(valid)
    //     if (!valid) {
    //       captcha(this.formdata.email, type);
    //       var interval = setInterval(() => {
    //         if (this.seconds == 1) {
    //           this.seconds = 60;
    //           clearInterval(interval);
    //         } else {
    //           this.seconds--;
    //         }
    //       }, 1000);
    //     }
    //   });
    },
    onModifyModalOk() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          modifyPassword(this.formdata.password, this.formdata.passwordConfirm).then(res => {
            if (res) {
              this.$Message.success('修改密码成功~');
            }
          });
        }
      })
    },
    onEmailUnbindModalOk() {
      unbindEmail(this.formdata.captcha).then(res => {
        if (res) {
          this.loadInfo();
        }
      });
    },
    onThirdUnbindModalOk() {
      unbindThird(this.unbindInfo.type, this.unbindInfo.openId).then(res => {
        if (res) {
          this.loadInfo();
        }
      });
    },
    loadInfo() {
      userInfo().then(res => {
        if (res) {
          this.$store.commit("updateInfo", res);
          this.nickName = this.userInfo.nickName;
        }
      });
    },
    onDestroyClick() {
      let random = Math.random() * (999999 - 100000) + 100000;
      this.confirmCode = Math.floor(random);
      this.showDestroyModal = true;
    },
    onDestroyModalOk() {
      if (this.confirmCode == this.confirmInput) {
        userDestroy().then(res => {
          if (res) {
            this.$router.replace({name: 'login'});
          }
        });
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .setting {
    width: 100%;
    height: 100%;
    background-color: var(--vp-c-bg-alt);
    color: var(--vp-c-text-1);
    position: relative;
    .setting-btn {
      border-radius: 8px;
      font-size: 14px;
      background-color: var(--vp-c-bg);
      color: var(--vp-c-text-1);
      &.back {
        position: absolute;
        top: 24px;
        left: 24px;
      }
      &.high {
        color: #FF8F8F;
        border-color: #FF8F8F;
      }
    }
    .user-title{
      .nick-name{
        font-weight: 500; 
        font-size: 14px; 
        color: var(--vp-c-text-1);
      }
      .member-title{
        color: var(--vp-c-text-3);
        font-size: 14px;
         margin-top: 8px;
      }
    }
    .setting-content {
      // height: calc(100% - 120px);
      height: 100%;
      max-width: 1024px;
      margin: 0px auto;
      padding: 30px;
      background-color: var(--vp-c-bg);
      border-radius: 8px;
      overflow-y: scroll;
      .base-info {
        margin-top: 30px;
        font-weight: 500;
        font-size: 20px;
      }
      .member-tip {
        margin-top: 20px;
        text-align: center;
        width: 100%;
        height: 60px;
        border-radius: 8px;
        line-height: 60px;
        color: var(--vp-c-text-1);
        background: linear-gradient(135deg, var(--ng-gradient-start) 0%, var(--ng-gradient-end) 100%);
      }
      .setting-cell {
        border-bottom: 1px solid var(--vp-c-border); 
        display: flex;
        padding: 30px 0;
        align-items: center;
        color: var(--vp-c-text-1);
        :deep(.ivu-select-dropdown) {
          border: 1px solid var(--vp-c-divider);
          background-color: var(--vp-c-bg-soft);
        }
        .dropdown-list {
          background-color: var(--vp-c-bg-soft);
          color: var(--vp-c-text-1);
          :deep(.ivu-dropdown-item){
            color: var(--vp-c-text-2);
            &:hover{
              background-color: var(--vp-c-bg);
              color: var(--vp-c-text-1);
            }
          }
        }
        div:first-child {
          flex: 1;
          p:first-child {
            font-size: 14px;
            color: var(--vp-c-text-1);
            font-weight: 500;
            margin-bottom: 8px;
          }
          p:last-child {
            font-size: 14px;
            color: var(--vp-c-text-3);
            i:first-child {
              margin-right: 4px;
            }
          }
        }
      }
      .account {
        .attach {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  :deep(.ivu-dropdown) {
    // display: flex;
    // align-items: center;
    cursor: pointer;
  }
  :deep(.ivu-input) {
    border-radius: 8px;
    background-color: var(--vp-c-bg);
    color: var(--vp-c-text-1);
    height: 48px;
  }
  :deep(.ivu-form-item-label) {
    color: var(--vp-c-text-1);
    height: 48px;
    line-height: 28px !important;
  }
  :deep(.ivu-input-suffix) {
    width: 102px;
  }
  .default-title {
    color: var(--vp-c-text-1);
    font-size: 16px;
  }
  .default-color{
    color: var(--vp-c-text-1);
  }
</style>