<template>
  <div :class="{'calendar-component': true, 'black': currentTheme=='black'}">
    <div class="content-header">
      <ButtonGroup style="margin-right: 8px;">
        <Button
          @click="onPrevClick"
          class="common-button"
          style="width: 28px;">
          <XIcon icon-class="arrow_left_white" size="20" style="margin-bottom: 2px;" v-if="currentTheme == 'black'"/>
          <XIcon icon-class="arrow_left" size="20" style="margin-bottom: 2px;" v-else/>
        </Button>
        <Button
          @click="onNextClick"
          class="common-button"
          style="width: 28px;">
          <XIcon icon-class="arrow_right_white" size="20" style="margin-bottom: 2px;" v-if="currentTheme == 'black'"/>
          <XIcon icon-class="arrow_right_black" size="20" style="margin-bottom: 2px;" v-else/>
        </Button>
      </ButtonGroup>
      <Button
        size="small"
        @click="onTodayClick"
        class="common-button">
        <XIcon icon-class="jin_white" size="16" style="margin-bottom: 4px;" v-if="currentTheme == 'black'"/>
        <XIcon icon-class="jin" size="16" style="margin-bottom: 4px;"  v-else/>
      </Button>
      <span style="flex: 1"></span>
      <span style="font-size: 18px;">{{ showTitle }}</span>
      <span style="flex: 1"></span>
      <!-- <Space>
        <Switch size="small" :model-value="showComplete" @on-change="onSwitchChange" />
        <span style="font-size: 14px;">显示已完成</span>
        <span style="color: #CCC; margin-left: 5px;">|</span>
      </Space> -->
      <div class="button-group">
        <Button
          v-for="item in typeData"
          :key="item.type"
          size="small"
          :class="'common-button '+(type==item.type?'button-high':'button-normal')"
          @click="onTagClick(item)"
        >{{ item.name }}</Button>
      </div>
      <Dropdown trigger="click" placement="bottom-end">
        <XIcon icon-class="more-col_white" style="font-size: 16px;-webkit-app-region: no-drag;" v-if="currentTheme == 'black'"/>
        <!-- <XIcon icon-class="more-col" style="font-size: 16px;-webkit-app-region: no-drag;" v-else/> -->
        <Icon v-else type="md-more" size="22" class="more-icon" />
        <template #list>
          <DropdownMenu class="dropdown-list" >
            <DropdownItem
              @click="onMoreClick">
              {{ showComplete ? '隐藏已完成' : '显示已完成' }}
            </DropdownItem>
            <DropdownItem
              @click="habitShowToggle">
              {{ hideHabit ? '显示习惯' : '隐藏习惯' }}
            </DropdownItem>
            <!-- <DropdownItem
                @click="changeTheme">
              {{ currentTheme == 'black' ? '正常主题' : '暗黑主题' }}
            </DropdownItem> -->
          </DropdownMenu>
        </template>
      </Dropdown>
    </div>
    <div class="calendar-bg">
      <FullCalendar ref="calendar" :options="calendarOptions" >
        <template #eventContent='arg'>
          <div class="event-content"
            :style="{
              color: currentTheme === 'black' ? 'white' : '',
              opacity: arg.event.backgroundColor === '#6b6b6b' ? 0.7 : 1,
              'text-decoration-line': arg.event.backgroundColor === '#6b6b6b' ? 'line-through' : 'none',
              'padding-left': '5px'
            }"
               @click="handleEventClick(arg)">
            <span class="dot" :style="{backgroundColor: arg.event.backgroundColor}" v-show="!arg.event.allDay"></span>
            <!-- <span style="margin-right: 5px" v-if="arg.timeText" :title="arg.timeText" :style="{ color: !arg.event.allDay ? arg.event.extendedProps.fontColor : '' }">{{ arg.timeText  }}</span>
            <span style="font-weight: 700; text-overflow: ellipsis; overflow: hidden;" :title="arg.event.title" :style="{ color: !arg.event.allDay ? arg.event.extendedProps.fontColor : '' }">{{ arg.event.title }}</span> -->
            <span style="margin-right: 5px" v-if="arg.timeText" :title="arg.timeText">{{ arg.timeText  }}</span>
            <span style="font-weight: 700; text-overflow: ellipsis; overflow: hidden;" :title="arg.event.title" >{{ arg.event.title }}</span>
          </div>
        </template>
      </FullCalendar>
      <!-- <Button class="show prev" shape="circle" icon="ios-arrow-back" @click="onPrevClick"></Button>
      <Button class="show next" shape="circle" icon="ios-arrow-forward" @click="onNextClick"></Button> -->
    </div>
    <Modal
      v-if="showModal"
      class="task-detail-modal"
      title=" "
      :mask="true"
      :width="520"
      draggable
      sticky
      scrollable
      v-model="showModal"
      @on-ok="onModalOk">
      <XToastTaskDetail :task="task"/>
    </Modal>
  </div>
</template>

<script>
  import XToastTaskDetail from '@/components/XToastTaskDetail';
  import calenderUtil from "@/common/calendar"

  import FullCalendar from '@fullcalendar/vue3'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import listPlugin from '@fullcalendar/list'
  import momentPlugin from '@fullcalendar/moment'
  import timelinePlugin from '@fullcalendar/timeline';
  import multiMonthPlugin from '@fullcalendar/multimonth';
  import rrulePlugin from '@fullcalendar/rrule'
  import { HolidayUtil } from 'lunar-javascript'

  import {
    userInfo,
    taskList,
    taskAdd,
    taskUpdate,
    noticeAdd,
    repeatAdd,
    projectList,
    repeatList
    // stickerList
  } from '@/common/api';

  // let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of toda

  const _typeData = {
    'yaer': {
      type: 'yaer',
      name: '年',
      key: 'multiMonthYear'
    },
    'month': {
      type: 'month',
      name: '月',
      key: 'dayGridMonth'
    },
    'week': {
      type: 'week',
      name: '周',
      key: 'timeGridWeek'
    },
    'day': {
      type: 'day',
      name: '日',
      key: 'timeGridDay'
    },
    'list': {
      type: 'list',
      name: '列表',
      key: 'listYear'
    },
  };
  let _colorMap = {};

  export default {
    props: {
      modelValue: {
        type: String,
        default: 'month',
      },
      theme: {
        type: String,
        default: 'black'
      }
    },
    emits: ['update:modelValue'],
    created() {
      HolidayUtil.fix('202501010120250101202501261020250129202501281120250129202501291120250129202501301120250129202501311120250129202502011120250129202502021120250129202502031120250129202502041120250129202502081020250129202504042120250404202504052120250404202504062120250404202504273020250501202505013120250501202505023120250501202505033120250501202505043120250501202505053120250501202505314120250531202506014120250531202506024120250531202509287020251001202510017120251001202510027120251001202510037120251001202510047120251001202510057120251001202510067120251001202510077120251001202510087120251001202510117020251001');
      this.daysOfYearInit()
    },
    components: {
      XToastTaskDetail,
      FullCalendar
    },
    async mounted() {
      this.calendar = this.$refs.calendar.calendar;
      this.calendarApi = this.$refs.calendar.getApi();
      // setTimeout(() => {
      //   this.onTodayClick();
      // }, 100);
      const projects = await projectList() || [];
      this.projectHideMap = projects.reduce((acc, { hide, projectIdStr }) => {
        acc[projectIdStr] = hide;
        return acc;
      }, {});
      this.loadData();
      this.currentTheme = this.theme
    },
    data() {
      let initialType = 'dayGridMonth';
      if (Object.keys(_typeData).indexOf(this.modelValue) > -1) {
        initialType = _typeData[this.modelValue].key;
      }
      return {
        yearDateList: [],
        holidayList: [],
        workdayList: [],
        weekendList:[],
        projectHideMap: {},
        currentTheme:'',
        target: 'all',
        menuList: [{
          'title': '显示已完成',
          'id': 1
        }],

        showModal: false,
        type: this.modelValue,
        typeData: _typeData,
        showTitle: '',
        calendar: {},
        calendarApi: {},
        showComplete: localStorage.getItem('calendarShowComplete') === 'true',
        hideHabit: localStorage.getItem('calendarHabitHide') === 'true',
        calendarOptions: {
          firstDay: localStorage.getItem("weekBegin") || 0,
          schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
          height: '100%',
          plugins: [ timelinePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, momentPlugin, multiMonthPlugin, rrulePlugin ],
          headerToolbar: null,
          allDayText: '全天',
          allDaySlot: true,
          slotEventOverlap: true, // 任务是否重叠
          slotLabelFormat: {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
            hour12: false // 设置时间为24小时
          },
          events: [],
          eventTimeFormat: 'HH:mm',
          slotDuration: '00:30:00',
          slotLabelInterval: '01:00',
          locale: 'zh-cn',
          editable: true,
          selectable: true,
          selectMirror: true,
          dayMaxEvents: true,
          weekends: true,
          droppable: true,
          dayCellContent: this.dayContentHook,
          select: this.handleDateSelect,
          eventClick: this.handleEventClick,
          eventDrop: this.handleEventDrop,
          eventResize: this.handleEventResize,
          eventsSet: this.handleEvents,
          moreLinkContent: this.moreContentHook,
          // moreLinkClick: this.handleMoreClick // more按钮点击 可以返回类型string或者function
          eventContent: this.eventContentHook,
        },
        normalList: [],
        completeList: [],
        task: {},
        darkColorMap: {
          0:'rgb(55 136 216 / 70%)',
          1:'rgb(88 119 250 / 40%)',
          2:'rgb(250 147 0 / 40%)',
          3:'rgb(241 33 33 / 40%)',
        },
        lightColorMap: {
          0:'rgb(55 136 216 / 70%)',
          1:'rgb(88 119 250 / 70%)',
          2:'rgb(250 147 0 / 70%)',
          3:'rgb(241 33 33 / 70%)',
        },
        darkFontColorMap: {
          0:'rgb(55 136 216 / 70%)',
          1:'rgb(88 119 250 / 70%)',
          2:'rgb(250 147 0 / 70%)',
          3:'rgb(241 33 33 / 70%)',
        },
        lightFontColorMap: {
          0:'rgb(55 136 216 / 90%)',
          1:'rgb(88 119 250 / 90%)',
          2:'rgb(250 147 0 / 90%)',
          3:'rgb(241 33 33 / 90%)',
        },
        themeAppearance: localStorage.getItem("theme-appearance") || "light"
      }
    },
    computed: {
      showList() {
        if (this.showComplete) {
          return [...this.normalList, this.completeList];
        }
        return this.normalList;
      },
      color(){
        return this.currentTheme == 'black'?'#F0F0F0':''
      },
      headerColor(){
        return this.currentTheme == 'black'?'#D4D4D4':''
      },
      headerBg(){
        return this.currentTheme == 'black'?'#212121':''
      }
    },
    methods: {
      onMoreClick() {
        this.onSwitchChange(!this.showComplete);
      },
      habitShowToggle() {

        this.hideHabit = !this.hideHabit;
        localStorage.setItem('calendarHabitHide', this.hideHabit)

        this.taskFilter()
      },
      onTagClick(item) {
        localStorage.setItem('calendarViewType', item.type)
        // this.$router.replace('/manager/calendar/' + item.type);
        this.$emit('update:modelValue', item.type);
        this.type = item.type;
        this.calendar.changeView(item.key);
        this.onTodayClick();
      },
      displayFestival(lunarFestival, festival, iDay) {
        if (lunarFestival && festival) {
          return lunarFestival + '-' + festival;
        } else if (lunarFestival) {
          return lunarFestival;
        } else if (festival) {
          return festival;
        } else {
          return iDay;
        }
      },
      dayContentHook(arg) {
        if (this.type == 'month') {
          let date = arg.date
          let text = date.getDate()
          let lunar = calenderUtil.solar2lunar(date.getFullYear(), date.getMonth() + 1, date.getDate())
          let iDay = lunar.lDay == 1 ? lunar.IMonthCn : lunar.IDayCn
          let subTitle = this.displayFestival(lunar.lunarFestival, lunar.festival, iDay)
          let holiday = HolidayUtil.getHoliday(date.getFullYear(), date.getMonth() + 1, date.getDate())
          let hv = ''
          if (holiday) {
            let isWork = holiday.isWork();
            let color = isWork ? '#43CF7C' : '#FD6C6C';
            let text = isWork ? '班' : '休';
            hv = `<span style="color:white;font-size:12px;width:16px;height:16px;line-height:16px;border-radius:8px;background-color:${color};position:absolute;top:5px;right:5px;text-align:center;">${text}</span>`
          }
          let style = 'display:flex;justify-content:space-between;width:100%;padding:6px 4px;'
          let dateStyle = ''
          if (arg.isToday) {
            dateStyle = 'border-radius:30px; background-color:#579CF9; color:white; width: 30px; height: 20px; text-align:center; line-height:22px;';
          }
          let v = `<div style="${style}"><span style="${dateStyle}">${text}</span><span style="color:var(--vp-c-text-1);">${subTitle}</span>${hv}</div>`;
          return { html: v }
        }
      },
      handleDateSelect(selectInfo) {
        this.showModal = true;
        this.task = {
          giveUp: false,
          completeTime: 0,
          title: '',
          level: 0,
          projectIdStr: '1' + this.$store.state.user.userId,
          positionWeight: 10,
          taskType: 0,
          startTime: selectInfo.start.getTime(),
          endTime: selectInfo.end.getTime(),
        };

        let calendarApi = selectInfo.view.calendar;
        calendarApi.unselect();
        // console.log(selectInfo);
        // let title = prompt('输入任务名称')
        // let calendarApi = selectInfo.view.calendar

        // calendarApi.unselect() // clear date selection

        // if (title) {
        //   calendarApi.addEvent({
        //     id: ++this.index,
        //     title,
        //     start: selectInfo.startStr,
        //     end: selectInfo.endStr,
        //     allDay: selectInfo.allDay
        //   })
        // }
      },
      handleEventClick(clickInfo) {
        this.showModal = true;
        this.task = Object.assign({}, clickInfo.event.extendedProps);
      },
      async handleEventDrop(eventDropInfo) {
        let event = eventDropInfo.event;
        let task = Object.assign({}, event.extendedProps);

        task.startTime = event.start.getTime();
        task.originTime = task.startTime;
        if (event.end) {
          task.endTime = event.end.getTime();
        } else {
          task.endTime = task.startTime + 60 * 60 * 1000; // 如果没有结束时间，默认结束时间为一个小时
        }
        await taskUpdate(task);
        this.loadData()
      },
      async handleEventResize(eventResizeInfo) {
        let event = eventResizeInfo.event;
        let task = Object.assign({}, event.extendedProps);

        task.startTime = event.start.getTime();
        task.originTime = task.startTime;
        task.endTime = event.end.getTime();
        await taskUpdate(task);
        this.loadData()
      },
      handleEvents(events) {
        this.currentEvents = events
      },
      onPrevClick() {
        console.log('onPrevClick')
        const date = new Date(this.calendar.currentData.currentDate);
        const year = date.getFullYear();
        const currentYear = new Date().getFullYear();
        if (year > currentYear) {
          this.holidayList = []
        } else {
          this.updateDaysOfYear(year)
        }
        this.calendarApi.prev();
        this.showTitle = this.calendar.view.title;
      },
      onNextClick() {
        const date = new Date(this.calendar.currentData.currentDate);
        const year = date.getFullYear();
        const currentYear = new Date().getFullYear();
        if (year > currentYear) {
          this.holidayList = []
        } else {
          this.updateDaysOfYear(year)
        }
        // console.log(this.calendarOptions.events)
        this.calendarApi.next();
        this.showTitle = this.calendar.view.title;
      },
      onTodayClick() {
        const date = new Date(this.calendar.currentData.currentDate);
        const year = date.getFullYear();
        const currentYear = new Date().getFullYear();
        if (year > currentYear) {
          this.holidayList = []
        } else {
          this.updateDaysOfYear(year)
        }
        this.calendarApi.today();
        this.showTitle = this.calendar.view.title;
      },
      handleMoreClick(info) {
        return null;
        // return 'timeGridDay';
      },
      moreContentHook(arg) {
        return '+' + arg.num + ' 更多';
      },
      eventContentHook(arg) {
        let startTime = arg.event.extendedProps.startTime;
        if (startTime) {
          let timeText = new Date(startTime).format("hh:mm");
          arg.timeText = timeText == '00:00' ? '' : timeText;
        }
      },
      isMidnightTimestamp(timestamp) {
        if (timestamp === undefined || timestamp === null || timestamp === 0) {
          return true;
        }
        const date = new Date(timestamp);
        return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
      },
      daysOfYearInit() {

        const currentYear = new Date().getFullYear();
        const holidayList = HolidayUtil.getHolidays(currentYear)
        const holidayMap = holidayList.reduce((acc, item) => {
          const dateString = item.getDay();
          acc[dateString] = {
            dateString: dateString,
            workday: item.isWork(),
            holiday: !item.isWork(),
          };
          return acc;
        }, {});

        const yearsDays = this.generateDates(currentYear)
        yearsDays.forEach(item=>{
          if (holidayMap[item.dateString]) {
            item.isWorkday = holidayMap[item.dateString].workday
            item.isHoliday = holidayMap[item.dateString].holiday
            item.isWeekend = item.Weekend 
          }
        })

        this.yearDateList = yearsDays
        this.updateDaysOfYear()
      },
      formatDateToYYYYMMDD(date){
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');  // 月份从0开始，需要加1
        const day = String(date.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`;
      },
      generateDates(year) {
        let dates = [];
        const startDate = new Date(`${year}-01-01`);
        const endDate = new Date(`${year}-12-31`);
        let currentDate = startDate;
        
        while (currentDate <= endDate) {
          const dayOfYear = this.getDayOfYear(currentDate);
          const isWorkday = !this.isWeekend(currentDate);
          const weekend = !isWorkday
          const dateString =  this.formatDateToYYYYMMDD(currentDate)
          dates.push({
            dateString,
            dayOfYear,
            isWorkday,
            weekend
          });
          
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
      },
      isWeekend(date) {
        const day = new Date(date).getDay();
        return day === 0 || day === 6;
      },
      updateDaysOfYear() {

        this.holidayList = this.yearDateList.filter(item=>item.isHoliday).map(item => item.dateString);
        this.workdayList = this.yearDateList.filter(item=>item.isWorkday).map(item => item.dateString);
        this.weekendList = this.yearDateList.filter(item=>item.weekend && !item.isWorkday).map(item => item.dateString);
      },
      getDayOfYear(dateStr) {
        const date = new Date(dateStr);
        const startOfYear = new Date(date.getFullYear(), 0, 1);
        const dayOfYear = Math.ceil((date - startOfYear) / (1000 * 60 * 60 * 24));
        
        return dayOfYear;
      },
      timestampToDateFormat(timestamp) {

          // let date = new Date(timestamp);
          // let year = date.getFullYear();
          // let month = (date.getMonth() + 1).toString().padStart(2, '0'); 
          // let day = date.getDate().toString().padStart(2, '0'); 

          // return `${year}-${month}-${day}`;
          // 创建一个 Date 对象
        let date = new Date(timestamp);

        // 获取各个部分并格式化
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');  // 月份是从 0 开始的，需加 1
        let day = String(date.getDate()).padStart(2, '0');
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let seconds = String(date.getSeconds()).padStart(2, '0');

        // 拼接成目标格式
        let formattedTime = `${year}${month}${day}T${hours}${minutes}${seconds}`;
        return formattedTime
      },
      convertDayToRRule(day) {
        const days = {
          "2": "MO", 
          "3": "TU", 
          "4": "WE", 
          "5": "TH", 
          "6": "FR", 
          "7": "SA", 
          "1": "SU" 
        };
        return days[day];
      },
      rruleFormatting(task) {
        // console.log(task)
        const {repeatMode, interval, weekdays, monthDays, endMode, repeatEndTimes, repeatEndDate, repeatEndHabitTimes, jumpHoliday, jumpWeekend, startMode, fixedDays} = task.repeat
        const {startTime, completeTime, habitDTO, endTime} = task
        const rule = {}
        let exdate = []
        const freqMap = {
          1: 'DAILY',
          2: 'WEEKLY',
          3: 'MONTHLY',
          4: 'YEARLY',
          5: 'DAILY',
          6: 'DAILY',
          7: 'DAILY',
          8: 'DAILY',
          15: 'DAILY'
        }
        rule.wkst = 0,
        rule.freq = freqMap[repeatMode] || 'DAILY'
        rule.interval = interval
        if (repeatMode === 8) {
          rule.count = 1
        }
        if (startTime) {
          rule.dtstart = this.timestampToDateFormat(startTime)
        }

        if (endMode === 0) {
          rule.count = null
        }

        if (endMode === 1) {
          if (repeatEndTimes) {
            rule.count = repeatEndTimes
          }
        }

        if (endMode === 3) {
          if (repeatEndHabitTimes && habitDTO?.habitDayNum) {
            rule.count = Math.ceil(repeatEndHabitTimes / habitDTO.habitDayNum)
          }
        }

        if (jumpHoliday) {
          exdate = this.holidayList
        }
        if (jumpWeekend && (repeatMode === 1 || repeatMode === 3)) {
          const merged = [...exdate, ...this.weekendList]
          exdate = [...new Set(merged)];
        }
        
        if (weekdays && repeatMode === 2 && startMode === 2) {
          const weekdaysMap = {
            2: 0,
            3: 1,
            4: 2,
            5: 3,
            6: 4,
            7: 5,
            8: 6,
          }
          rule.byweekday = weekdays.map(item => {
            return weekdaysMap[item]
          })
        }

        if (monthDays && repeatMode === 3  && startMode === 2) {
          const monthDaysTmp = monthDays.map(item => --item)
          rule.bymonthday = monthDaysTmp
        }

        if (startMode === 3 && fixedDays) {
          const fixedDaysTmp = fixedDays.map(item => this.formatDateToYYYYMMDD(new Date(item)))
          
          rule.byyearday = fixedDaysTmp.map(item => (this.getDayOfYear(item)));
          rule.freq = 'DAILY'
        }


        if (repeatMode === 5) {
          rule.byweekday = [0, 1, 2, 3, 4]
        }

        if (repeatMode === 6) {
          const eventDates = this.workdayList.map(item => (this.getDayOfYear(item)));
          rule.byyearday = eventDates
          const nextYear = new Date(new Date().getFullYear() + 1, 0, 1)
          rule.until = this.timestampToDateFormat(nextYear)
        }

        if (repeatMode === 7) {
          const eventDates = this.holidayList.map(item => (this.getDayOfYear(item)));
          rule.byyearday = eventDates
          const nextYear = new Date(new Date().getFullYear() + 1, 0, 1)
          rule.until = this.timestampToDateFormat(nextYear)
        }

        if (endMode === 2) {
          if (repeatEndDate) {
            rule.until = this.timestampToDateFormat(repeatEndDate)
          }
        }

        if (completeTime) {
          rule.until = this.timestampToDateFormat(completeTime)
        }

        if (endTime) {
          rule.until = this.timestampToDateFormat(endTime)
        }

        return {rule, exdate}
      },
      loadData() {
        userInfo().then( async info => {
          if (info && info.userId) {
            this.$store.commit("updateInfo", info);
            let projectId = '-2' + info.userId;
            const repeats = await repeatList()
            let tasks = await taskList(projectId)
            tasks.forEach(item=>{
              const taskIdStr = item.taskIdStr
              item.repeat = repeats.find((repeat) => {
                return repeat.taskIdStr === taskIdStr;
              });

            })
            // console.log(tasks)
            tasks = tasks.filter(item => {
              return !this.projectHideMap[Number(item.projectIdStr)]
            })
            this.completeList = [];
            this.normalList = [];
            tasks.forEach(element => {
              if (element.startTime) {
                const isStart = this.isMidnightTimestamp(element.startTime) 
                const isEnd = this.isMidnightTimestamp(element.endTime)
                let obj = {
                  id: element.taskIdStr,
                  title: element.title,
                  start: element.startTime,
                  end: element.endTime,
                  extendedProps: element,
                  // allDay: element.endTime - element.startTime > 24 * 60 * 60 * 1000,
                  allDay: isStart && isEnd,
                };
                if (element.hasRepeat) {
                  // console.log(element)
                  // if ([1, 2, 3, 4, 5, 6, 7].includes(element.repeat.repeatMode)) {
                  //   const {rule, exdate} = this.rruleFormatting(element)
                  //   obj.rrule = rule
                  //   obj.exdate = exdate
                  // }
                  const {rule, exdate} = this.rruleFormatting(element)
                  obj.rrule = rule
                  obj.exdate = exdate
                }
                if (obj.rrule && !obj.rrule.interval) {
                  obj.rrule.interval = 0
                }
                if (element.completeTime > 0 || element.giveUp) {
                  obj.color = '#6b6b6b';
                  this.completeList.push(obj);
                } else {
                  // let color = _colorMap[element.taskIdStr];
                  // if (!color) {
                  //   const r = Math.floor(Math.random()*256);
                  //   const g = Math.floor(Math.random()*256);
                  //   const b = Math.floor(Math.random()*256);
                  //   color = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
                  //   _colorMap[element.taskIdStr] = color;
                  // }
                  obj.color = this.themeAppearance === 'light' ? this.lightColorMap[element.level] : this.darkColorMap[element.level];
                  obj.fontColor = this.themeAppearance === 'light' ? this.lightFontColorMap[element.level] : this.darkFontColorMap[element.level];
                  this.normalList.push(obj);
                }
              }
            });

            this.taskFilter()

            const item = localStorage.getItem('calendarViewType') || 'month'
            const calendarView = this.typeData[item]
            this.onTagClick(calendarView)
            // this.onTodayClick();
          }
        });
      },
      taskFilter() {

        let filteredTasks = [...this.normalList, ...this.completeList];

        if (!this.showComplete) {
          filteredTasks = this.normalList;
        }

        if (this.hideHabit) {
          filteredTasks = filteredTasks.filter(item => {
            return item.extendedProps.taskType !== 2;
          });
        }

        this.calendarOptions.events = filteredTasks;
      },
      taskTitle(task) {
        if (!task.content) return '';
        let list = JSON.parse(task.content);
        for (let i = 0; i < list.length; i++) {
          const item = list[i];
          if (item.spanList && item.spanList.length > 0) {
            for (let j = 0; j < item.spanList.length; j++) {
              const subItem = item.spanList[j];
              if (subItem.content && subItem.content.length > 0) {
                return subItem.content;
              }
            }
          }
        }
        return '';
      },
      onSwitchChange(value) {
        this.showComplete = value;
        localStorage.setItem('calendarShowComplete', value)
        this.taskFilter()
      },
      onModalOk() {
        if (!this.task.taskIdStr) {
          taskAdd(this.task).then(res => {
            if (res) {
              if (this.task.notices && this.task.notices.length > 0) {
                this.task.notices.forEach(element => {
                  element.taskIdStr = res;
                  element.taskId = parseInt(res, 10);
                  noticeAdd(element);
                });
              }
              if (this.task.repeat && Object.keys(this.task.repeat).length > 0) {
                this.task.repeat.taskIdStr = res;
                this.task.repeat.taskId = parseInt(res, 10);
                repeatAdd(this.task.repeat);
              }
              this.loadData();
            }
          });
        } else {
          this.loadData();
        }
      },
      changeTheme(){
        if(this.currentTheme == 'black'){
          this.currentTheme = ''
        }else{
          this.currentTheme = 'black'
        }
      }
    }
  }
</script>

<style lang="less" scoped>
@headerColor: v-bind(headerColor);
@headerBg: v-bind(headerBg);
@color: v-bind(color);

:deep(.fc-more-link){
  color:@color
}
:deep(.fc-col-header-cell-cushion){
  color:@headerColor
}
:deep(thead .fc-scrollgrid-sync-inner){
  background:@headerBg
}
:deep(.fc-daygrid-day-number span){
  color:@color
}

:deep(.fc-popover-body) {
  max-height: 300px;
  overflow-y: scroll;
}
:deep(.fc-daygrid-day-number){
  width: 100%;
}
:deep(.fc-timegrid-event .fc-event-main){
  overflow: hidden;
}
.calendar-component {
    width: 100%;
    height: 100%;
    background-color: var(--vp-c-bg);
    color: var(--vp-c-text-1);
    overflow: hidden;
    .fc .fc-scrollgrid{
      border-radius: 8px;
    }
    .fc-theme-standard td {
      &:hover{
        background-color: #444;
      }
    }
    .content-header {
      padding: 20px;
      display: flex;
      cursor: move;
      -webkit-app-region: drag;
      .common-button {
        -webkit-app-region: no-drag;
        padding: 0px;
        min-width: 40px;
        height: 24px;
        background-color: #d0d0d04a;
        border: none;
        color: var(--vp-c-text-1);
      }
      .button-group {
        -webkit-app-region: no-drag;
        background-color: #d0d0d04a;
        color: var(--vp-c-text-1);
        border-radius: 4px;
        height: 24px;
        margin-right: 10px;
        .ivu-btn-small {
          padding: 0 12px;
        }
        .button-normal {
          background-color: transparent;
          border-color: transparent;
          font-size: 12px;
        }
        .button-high {
          background-color: #a3a3a378;
          font-size: 12px;
        }
      }
    }
    .calendar-bg {
      -webkit-app-region: no-drag;
      height: calc(100% - 72px);
      padding: 0px 30px 30px 30px;
      position: relative;
      &:hover {
        .show {
          display: inline;
        }
      }
      button {
        position: absolute;
        display: none;
        top: calc(50% - 20px);
        background-color: lightgrey;
        color: white;
        text-align: center;
        font-size: 20px;
        z-index: 9999;
        &.prev {
          left: 20px;
        }
        &.next {
          right: 20px;
        }
      }
      .event-content{
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        font-size: 12px;
        line-height: 20px;
      }
      .dot {
        margin: 0 4px;
        // box-sizing: content-box;
        // width: 0;
        // height: 0;
        // border: 4px solid #3788d8;
        // border: calc(var(--fc-daygrid-event-dot-width, 8px)/2) solid var(--fc-event-border-color,#3788d8);
        // border-radius: 4px;
        // border-radius: calc(var(--fc-daygrid-event-dot-width, 8px)/2);
        background-color: var(--fc-event-border-color);
        width: 8px;
        height: 8px;
        border-radius: 4px;
        display: inline-block;
      }
    }
     &.black {
       background-color: rgba(0, 0, 0, 0.7);
       color: white;
       .common-button {
         background-color: #E4EFFE33;
       }
       .button-group {
         background-color: #E4EFFE33;
         //border: 1px solid #4d4d4d;
         .button-normal {
           color: white;
         }
         .button-high {
           color: #85A9FF;
           //background-color: #ADC6FF33;
           background-color: #2F3B59;
         }
       }
       --fc-small-font-size: .85em;
       --fc-page-bg-color: #000;
       --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
       --fc-neutral-text-color: #808080;
       --fc-border-color: #F2F3F733;

       --fc-button-text-color: #fff;
       --fc-button-bg-color: red;
       --fc-button-border-color: #2C3E50;
       --fc-button-hover-bg-color: #1e2b37;
       --fc-button-hover-border-color: #1a252f;
       --fc-button-active-bg-color: #1a252f;
       --fc-button-active-border-color: #151e27;

       --fc-event-bg-color: #3788d8;
       --fc-event-border-color: #3788d8;
       --fc-event-text-color: #fff;
       --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

       --fc-more-link-bg-color: #d0d0d0;
       --fc-more-link-text-color: inherit;

       --fc-event-resizer-thickness: 8px;
       --fc-event-resizer-dot-total-width: 8px;
       --fc-event-resizer-dot-border-width: 1px;

       --fc-non-business-color: rgba(215, 215, 215, 0.3);
       --fc-bg-event-color: rgb(143, 223, 130);
       --fc-bg-event-opacity: 0.3;
       --fc-highlight-color: #3D87DA40;
       // --fc-today-bg-color: rgba(255, 220, 40, 0.15);
       --fc-today-bg-color: #3D87DA12;
       --fc-now-indicator-color: red;
     }
  }
</style>
